
	import { defineComponent, PropType } from 'vue';
	import { IServerData } from '@/interfaces/Search';
	import { IEditable, IEntity } from '@/interfaces/Data';
	import { Alert, AlertType } from '@/interfaces/Notification';
	import { ApplicationService } from '@/services/ApplicationService';
	import { FormService } from '@/services/FormService';
	import { VForm } from 'vuetify/components'
	import { GlobalAlertService } from '@/services/GlobalAlertService';
	import { ApiResponse } from '@/interfaces/ApiResponse';
	import { User } from '@/interfaces/User';
	import { BaseService } from '@/services/BaseService';
	import { UserService } from '@/services/UserService';

	interface Data extends IEditable<User> {
		password: string,
		confirmPassword: string,
		progress: boolean,
		rulesPassword: Array<(value: string) => {}>,
		rulesConfirmPassword: Array<(value: string) => {}>,
	}

	export default defineComponent({
		props: {
			propEntity: {
				type: Object as PropType<User>,
				default: null,
			},
		},
		data(): Data {
			this.updateAlert();

			return {
				password: '',
				confirmPassword: '',
				editableEntity: this.propEntity,
				progress: false,
				rulesPassword: [
					value => {
						if (value && value.length >= 6) return true;

						return 'Password should be at least 6 characters.'
					},
				],
				rulesConfirmPassword: [
					value => {
						if (value == (this.$data as Data).password) return true;

						return 'Password and Confirm Password should be same.'
					},
				],
			}
		},
		watch: {
			propEntity(newValue, oldValue) {
				this.editableEntity = newValue;
			},
		},
		methods: {
			close() {
				this._close();
			},

			_close(entity?: User) {
				this.$emit("close-modal", entity);
			},

			updateAlert(alertType?: AlertType, message?: string) {
				GlobalAlertService.showAlert(alertType ? { type: alertType, message: message } as Alert : null);
			},

			trySave() {
				FormService.processIfValid(this.$refs.form as VForm, this.save, this.onError);
			},

			save() {
				this.updateAlert();
				this.progress = true;
				UserService.changePassword(this.editableEntity?.id || 0, this.password, this.onError)
					.then(json => {
						const response = json as ApiResponse<boolean>;
						if (response.errorMessage) {
							throw response;
						}
						this.password = this.confirmPassword = '';
						this.close();
						setTimeout(() => GlobalAlertService.showAlert({ type: AlertType.success, message: `Password changed successfully.` }), 0);
					})
					.catch(reason => BaseService.processError(reason, this.onError))
					.finally(() => {
						this.progress = false;
					});
			},

			onError(error: string) {
				this.updateAlert(AlertType.error, error);
			},
		},
	})
