export enum AlertType {
	success,
	warning,
	info,
	error
}
export interface Alert {
	type: AlertType,
	message: string,
	code?: null | string,
	httpStatus?: null | number,
}