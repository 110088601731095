
	import { defineComponent, PropType, DefineComponent, ExtractPropTypes } from 'vue';
	import { PageInfo } from '@/interfaces/Search'
	import { EditableGridData } from '@/interfaces/Grid'
	import { GridService } from '@/services/GridService'
	import { EditFormProps } from '@/interfaces/Form';
	import { BotType, LawFirmBot } from '@/interfaces/LawFirmBot';
	import { AreaOfLaw } from '@/interfaces/AreaOfLaw';

	export default (function defineGenericComponent<TFormProps extends EditFormProps<LawFirmBot>>() {
		const editableMethods = GridService.editableGridMethods<LawFirmBot, TFormProps>();
		interface Data extends EditableGridData<LawFirmBot, TFormProps> {
			firmId: number,
		}
		return defineComponent({
			name: 'LawFirmBotGrid',
			props: {
				propId: {
					type: Number,
					default: 0,
				},
				propAddItem: {
					type: Object as PropType<LawFirmBot>,
					default: null,
				},
				propEditForm: {
					type: Object as PropType<DefineComponent<TFormProps, any, any>>,
					default: null,
				},
				propDeleteForm: {
					type: Object as PropType<DefineComponent<TFormProps, any, any>>,
					default: null,
				},
				propAreas: {
					type: Object as PropType<AreaOfLaw[]>,
					default: null
				},
				propBotTypes: {
					type: Object as PropType<BotType[]>,
					default: null
				},

			},
			data(): Data {
				return {
					...GridService.defaultEditableGridData('lawfirmbot', this.propEditForm, this.propDeleteForm),
					headers: [
						{
							title: 'ID',
							sortable: false,
							key: 'id',
							align: 'end',
							width: '50px',
						},
						{ title: 'Bot Id', key: 'botId', sortable: false, align: 'start' },
						{ title: 'Bot Type', key: 'botType', sortable: false, align: 'start', formatter: (value: any) => value.name },
						{ title: 'Area of law', key: 'area', sortable: false, align: 'start', formatter: (value: any) => value.name },
						{ title: 'Actions', key: 'actions', sortable: false, align: 'end', width: '100px' },
					],
					editableEntity: {
						id: 0,
						area: null,
						areaOfLawId: 0,
						botId: 0,
						botType: null,
						botTypeId: 0,
						createDate: new Date(),
						updateDate: new Date(),
						lawFirmId: this.propId,
					},
					firmId: this.propId,
				};
			},
			created() {
				this.initialize();
			},
			watch: {
				propAddItem: function (newVal, oldVal) {
					if (newVal) {
						this.editItem(newVal);
					}
				}
			},
			methods: {
				...editableMethods,
				loadItems: function (pageInfo: PageInfo): void {
					this.loading = true;
					pageInfo.search = this.firmId + '';
					const storeKey = `grid-${this.firmId}-${pageInfo.itemsPerPage}`;
					this.fetchData(pageInfo, storeKey);
				},
				editItem: function (item: LawFirmBot): void {
					this.editableEntity = {
						...item
					};
					if (this.editForm) {
						type formPropsType = InstanceType<typeof this.editForm>["$props"];
						const formProps: formPropsType = {
							propEntity: this.editableEntity,
							propController: this.controller,
							propAreas: this.propAreas,
							propBotTypes: this.propBotTypes,
						};
						this.showModal(this.editForm, formProps);
					}
				}
			}
		});
	})();
