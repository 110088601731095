
	import { defineComponent, PropType } from 'vue';
	import { IServerData } from '@/interfaces/Search';
	import { IEditable, IEntity } from '@/interfaces/Data';
	import { Alert, AlertType } from '@/interfaces/Notification';
	import { ApplicationService } from '@/services/ApplicationService';
	import { FormService } from '@/services/FormService';
	import { VForm } from 'vuetify/components'
	import { GlobalAlertService } from '@/services/GlobalAlertService';
	import { ApiResponse } from '@/interfaces/ApiResponse';
	import { User } from '@/interfaces/User';
	import { BaseService } from '@/services/BaseService';
	import { VersionsStore } from '@/Stores/VersionStore';

	interface Data extends IServerData, IEditable<User> {
		progress: boolean,
		rulesUsername: Array<(value: string) => {}>,
	}

	export default defineComponent({
		props: {
			propEntity: {
				type: Object as PropType<User>,
				default: null,
			},
			propController: {
				type: String,
				default: null,
			},
		},
		data(): Data {
			this.updateAlert();

			return {
				editableEntity: this.propEntity,
				controller: this.propController,
				progress: false,
				rulesUsername: [
					value => {
						if (value) return true;

						return 'Username cannot be empty.'
					},
				],
			}
		},
		watch: {
			propEntity(newValue, oldValue) {
				this.editableEntity = newValue;
			},
		},
		methods: {
			close() {
				this._close();
			},

			_close(entity?: User) {
				this.$emit("close-modal", entity);
			},

			updateAlert(alertType?: AlertType, message?: string) {
				GlobalAlertService.showAlert(alertType ? { type: alertType, message: message } as Alert : null);
			},

			trySave() {
				FormService.processIfValid(this.$refs.form as VForm, this.save, this.onError);
			},

			save() {
				this.updateAlert();
				this.progress = true;
				ApplicationService.updateEntity(this.editableEntity as User, this.controller, this.onError)
					.then(json => {
						const response = json as ApiResponse<User>;
						if (response.errorMessage) {
							throw response;
						}
						VersionsStore.increment(this.controller);
						this.editableEntity = response.data;
						this._close(this.editableEntity as User);
					})
					.catch(reason => BaseService.processError(reason, this.onError))
					.finally(() => {
						this.progress = false;
					});
			},

			onError(error: string) {
				this.updateAlert(AlertType.error, error);
			},
		},
	})
