
	import { defineComponent, PropType } from 'vue';
	import { IServerData } from '@/interfaces/Search';
	import { IEditable, IEntity } from '@/interfaces/Data';
	import { Alert, AlertType } from '@/interfaces/Notification';
	import { ApplicationService } from '@/services/ApplicationService';
	import { GlobalAlertService } from '@/services/GlobalAlertService';
	import { onErrorFunc } from '@/interfaces/Service';
	import { ApiResponse } from '@/interfaces/ApiResponse';
	import { VersionsStore } from '@/Stores/VersionStore';

	export default (function defineGenericComponent<T>() {
		interface Data extends IServerData, IEditable<T> {
			progress: boolean,
		}

		return defineComponent({
			props: {
				propEntity: {
					type: Object as PropType<T>,
					default: null,
				},
				propController: {
					type: String,
					default: null,
				},
			},
			data(): Data {
				this.updateAlert();

				return {
					editableEntity: this.propEntity as T,
					controller: this.propController,
					progress: false,
				}
			},
			watch: {
				propEntity(newValue, oldValue) {
					this.editableEntity = newValue;
				},
			},
			methods: {
				close() {
					this._close();
				},

				_close(entity?: T) {
					this.$emit("close-modal", entity);
				},

				updateAlert(alertType?: AlertType, message?: string) {
					GlobalAlertService.showAlert(alertType ? { type: alertType, message: message } as Alert : null);
				},

				deleteEntity() {
					this.updateAlert();
					this.progress = true;
					ApplicationService.deleteEntity(this.editableEntity as IEntity, this.controller, this.onError as onErrorFunc)
						.then(json => {
							const response = json as ApiResponse<T>;
							if (response.errorMessage) {
								throw response;
							}
							VersionsStore.increment(this.controller);
							this._close(this.editableEntity as T);
						})
						.finally(() => {
							this.progress = false;
						});
				},

				onError(error: string) {
					this.updateAlert(AlertType.error, error);
				},

			},
		})
	})();

