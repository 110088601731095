
	import { defineComponent } from 'vue'
	import { UserService } from '@/services/UserService';
	import { GlobalAlertService } from '@/services/GlobalAlertService';
	import { Alert, AlertType } from '@/interfaces/Notification';
	import { VForm } from 'vuetify/components';
	import { FormService } from '@/services/FormService';
	import { VersionsStore } from '@/Stores/VersionStore';
	import logoImage from "@/assets/logo-150.png";

	interface Data {
		username: string,
		password: string,
		progress: boolean,
		rulesUsername: Array<(value: string) => {}>,
		rulesPassword: Array<(value: string) => {}>,
		alert: null | Alert,
		alertContext: GlobalAlertService,
		logoImage: string,
	}

	export default defineComponent({
		data(): Data {
			return {
				logoImage: logoImage,
				alertContext: GlobalAlertService,
				alert: null,
				progress: false,
				username: '',
				password: '',
				rulesUsername: [
					value => {
						if (value) return true;

						return 'Username cannot be empty.'
					},
				],
				rulesPassword: [
					value => {
						if (value) return true;

						return 'Password cannot be empty.'
					},
				]
			}
		},
		methods: {
			tryLogin(ev: Event) {
				FormService.processIfValid(this.$refs.form as VForm, this.login, this.onError);
				ev.preventDefault();
				return false;
			},
			login() {
				this.progress = true;
				const returnUrl = VersionsStore.getData('nav', 'returnUrl') ?? '';
				UserService.login(this.username, this.password, returnUrl, this.onError)
					.finally(() => {
						this.progress = false;
					});
			},
			onError(error: string) {
				this.showAlert({ type: AlertType.error, message: error });
			},
			showAlert(alert: Alert) {
				this.alert = alert;
			},
		}
	});
