import { DefineComponent, ExtractPropTypes } from "vue";
import { Alert, AlertType } from "@/interfaces/Notification";

export class GlobalAlertService {
	static contexts: any[] = [];

	static initialize() {
		const context = this.contexts[this.contexts.length - 1];
		if (context) {
			this.showAlert = context.showAlert;
		}
		else {
			this.showAlert = (alert: Alert | null) => {
				return;
			}
		}
	}

	static setContext(context: any) {
		this.contexts.push(context);
		this.initialize();
	}

	static removeContext(context: any) {
		this.contexts.splice(this.contexts.indexOf(context), 1);
		this.initialize();
	}

	static showAlert(alert: Alert | null): void {
		return;
	}

	static getAlertTypeName(type?: AlertType, prefix: string = '') {
		return typeof(type) == 'number' ? prefix + AlertType[type] : '';
	}
}