import { ApiResponse } from "@/interfaces/ApiResponse";
import { HttpError, onErrorFunc } from "@/interfaces/Service";
import { UserService } from "@/services/UserService";

export class BaseService {
	static processError<T>(error: string | ApiResponse<T> | HttpError, onError?: onErrorFunc) {
		if (onError) {
			if (typeof (error) == 'string') {
				onError(error);
			}
			else {
				if ((error as ApiResponse<T>).name == 'ApiResponse') {
					const apiError = error as ApiResponse<T>;

					onError(apiError.errorMessage || 'API error');
				}
				else {
					const httpError = error as HttpError;
					if (httpError) {
						const errorMessage = 'Oops, something went wrong';
						switch (httpError.status) {
							case 404:
							case 500:
							case 501:
							case 502:
							case 503:
								break;
							case 401:
							case 403:
								UserService.dropUserToken();
								break;
						}
						onError(errorMessage, httpError, httpError.status, httpError.name == 'AbortError');
						/*httpError.text.then((text: any) => {
							onError(errorMessage ? errorMessage : text, httpError, httpError.status)
						});*/
					}
				}
			}
		}
	}
}