import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "pa-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_hover = _resolveComponent("v-hover")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_navigation_drawer, {
            theme: "dark",
            modelValue: _ctx.drawer,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
            rail: _ctx.rail,
            permanent: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rail = false))
          }, {
            append: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _withDirectives(_createVNode(_component_v_btn, {
                  block: "",
                  color: "indigo-darken-3",
                  onClick: _ctx.logout
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Logout ")
                  ]),
                  _: 1
                }, 8, ["onClick"]), [
                  [_vShow, !_ctx.rail]
                ]),
                _createVNode(_component_v_hover, null, {
                  default: _withCtx(({ isHovering, props }) => [
                    _withDirectives(_createVNode(_component_v_icon, _mergeProps({ icon: "mdi-logout" }, props, {
                      color: isHovering ? 'info' : '',
                      onClick: _ctx.logout
                    }), null, 16, ["color", "onClick"]), [
                      [_vShow, _ctx.rail]
                    ])
                  ]),
                  _: 1
                })
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                "prepend-icon": "mdi-account-circle",
                title: _ctx.userDisplay,
                link: true
              }, {
                append: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-chevron-left",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.rail = !_ctx.rail), ["stop"]))
                  })
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_list, {
                nav: "",
                density: "compact"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      link: true,
                      key: item.title,
                      title: item.title,
                      to: item.to
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          icon: item.icon
                        }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1032, ["title", "to"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "rail"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}