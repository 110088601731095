export interface VersionsHash {
	[entity: string]: number;
}

export interface DatasHash {
	[entity: string]: { version: number, data: any };
}

export class VersionsStore {
	private static versions = {} as VersionsHash;
	private static datas = {} as DatasHash;
	static increment(entity: string) {
		const newVersion = this.versions[entity] = this.getVersion(entity) + 1;
		return newVersion;
	}
	static getVersion(entity: string): number {
		return (this.versions[entity] || 0);
	}
	static putData(entity: string, key: string, data: any) {
		this.datas[`${entity}-${key}`] = {
			version: this.getVersion(entity),
			data: data
		}
	}
	static getData(entity: string, key: string) {
		const store = this.datas[`${entity}-${key}`];
		return store && store.version == this.getVersion(entity) ? store.data : null;
	}
}