import { DefineComponent, ExtractPropTypes } from "vue";

export class ModalService {
	static setContext(context: any) {
		this.show = context.show;
	}

	static show<TResult, TProps>(component: DefineComponent<TProps, any, any>, params: ExtractPropTypes<TProps>, css = ''): Promise<TResult> {
		return new Promise<TResult>(() => { });
	}
}