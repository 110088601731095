
	import { defineComponent, PropType } from 'vue';
	import { UserService } from '@/services/UserService';
	interface Data {
		drawer: boolean,
		rail: boolean,
		userDisplay: string,
		menuItems: any[],
	}
	export default defineComponent({
		props: {
			propRail: {
				type: Boolean,
				default: false,
			}
		},
		data(): Data {
			return {
				drawer: true,
				rail: this.propRail,
				menuItems: [
					{ icon: "mdi-domain", title: "Law Firms", to: "/firms" },
					{ icon: "mdi-home-city", title: "Zip codes", to: "/zip-codes" },
					{ icon: "mdi-scale-balance", title: "Areas of law", to: "/areas" },
					{ icon: "mdi-account-group", title: "Users", to: "/users" },
				],
				userDisplay: UserService.getDisplayName(),
			}
		},
		created() {
		},
		watch: {
			rail(newValue, oldValue) {
				this.$emit('update:propRail', newValue);
			},
		},
		methods: {
			logout() {
				UserService.dropUserToken();
			}
		},
		name: "navigation-panel"
	});
