import { createRouter, createWebHistory, RouteLocationNormalized, Router, RouteRecordRaw } from 'vue-router'
import Home from '@/views/FirmsView.vue'
import Firm from '@/views/FirmView.vue'
import Areas from '@/views/AreasView.vue'
import ZipCodes from '@/views/ZipcodesView.vue'
import Users from '@/views/UsersView.vue'
import Login from '@/views/LoginView.vue'
import { UserService } from '@/services/UserService'
import MainLayout from '@/views/MainLayout.vue'
import { GlobalAlertService } from '@/services/GlobalAlertService'
import { VersionsStore } from '@/Stores/VersionStore'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			name: 'login',
			path: "/login",
			component: Login,
			meta: {
				anonymous: true
			}
		},
		{
			path: '/',
			redirect: "/firms",
			component: MainLayout,
			children: [{
				name: 'firms',
				path: '/firms',
				component: Home,
				meta: {
					title: 'Law firms',
					keepAlive: true,
				},
			},
			{
				name: 'firm',
				path: "/firms/:propId",
				component: Firm,
				props: true,
			},
			{
				name: 'areas',
				path: '/areas',
				component: Areas,
				meta: {
					title: 'Areas of law',
					keepAlive: true,
				},
			},
			{
				name: 'zipCodes',
				path: '/zip-codes',
				component: ZipCodes,
				meta: {
					title: 'Zip codes',
					keepAlive: true,
				},
			},
			{
				name: 'users',
				path: '/users',
				component: Users,
				meta: {
					title: 'Users',
					keepAlive: true,
				},
			}]
		}
	],
});

router.beforeEach((to, from, next) => {
	GlobalAlertService.showAlert(null);
	const publicPages = ['/login'];
	const authRequired = !to.meta.anonymous;
	const userToken = UserService.getUserToken();
	document.title = 'Txt My Lawyer' + (to.meta?.title ? ': ' + to.meta.title : '');
	if (authRequired) {
		if (!userToken) {
			VersionsStore.putData('nav', 'returnUrl', to.fullPath)
			return next('/login');
		}
	}
	else {
		if (userToken) {
			return next("/");
		}
	}
	return next();
})

const keepAliveItems: string[] = [];
function collectKeepAliveRoutes(routes: RouteRecordRaw[]) {
	routes.forEach(route => {
		if (route.children?.length) {
			collectKeepAliveRoutes(route.children);
		}
		if (route.meta?.keepAlive && route.component?.name) {
			keepAliveItems.push(route.component.name)
		}
	})
}
collectKeepAliveRoutes(router.options.routes as RouteRecordRaw[]);

export {
	router,
	keepAliveItems
}
