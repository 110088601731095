import { PageInfo, PageResult } from '@/interfaces/Search'
import { GridData, SelectableGridData, EditableGridData, GridFunctions, EditableGridFunctions, GridColumn, } from '@/interfaces/Grid'
import { EditFormProps } from '@/interfaces/Form'
import { ApplicationService } from '@/services/ApplicationService'
import { ModalService } from '@/services/ModalService'
import { DefineComponent, ExtractPropTypes } from 'vue'
import { ApiResponse } from '@/interfaces/ApiResponse'
import { GlobalAlertService } from '@/services/GlobalAlertService'
import { Alert, AlertType } from '@/interfaces/Notification'
import { HttpError } from '@/interfaces/Service';
import { BaseService } from '@/services/BaseService'
import { VersionsStore } from '@/Stores/VersionStore'

export class GridService {
	static defaultGridData<T>(controller?: string): GridData<T> {
		return {
			loading: true,
			itemsPerPageOptions: [
				{ value: 10, title: '10' },
				{ value: 25, title: '25' }
			],
			headers: [],
			search: '',
			totalItems: 0,
			page: 1,
			itemsPerPage: 25,
			sortBy: [],
			entities: [],
			controller: controller ?? 'unknown',
			version: VersionsStore.getVersion(controller || ''),
		}
	}
	static defaultSelectableGridData<T>(controller?: string): SelectableGridData<T> {
		return {
			...GridService.defaultGridData(controller),
			selectedIndex: -1,
		}
	}
	static defaultEditableGridData<T, TFormProps extends EditFormProps<T>>(controller?: string, editForm?: DefineComponent<TFormProps, any, any>, deleteForm?: DefineComponent<TFormProps, any, any>): EditableGridData<T, TFormProps> {
		const data = GridService.defaultSelectableGridData<T>(controller);
		const methods = GridService.editableGridMethods();
		return {
			...data,
			editableEntity: null,
			editForm: editForm,
			deleteForm: deleteForm,
		}
	}
	static gridMethods(): GridFunctions {
		interface Grid<T> extends GridData<T>, GridFunctions {
		}
		let abortController: null | AbortController = null;

		const onFetchDataError = function <T>(this: GridData<T>, error: string, reason?: HttpError, httpStatus?: number, canBeIgnored?: boolean) {
			if (!canBeIgnored) {
				this.loading = false;
				this.totalItems = 0;
				this.entities = [];

				const alert = { type: AlertType.error, message: 'Uknown error' } as Alert;
				const showError = (alert: Alert) => {
					GlobalAlertService.showAlert(alert);
				}

				alert.httpStatus = reason?.status;
				alert.message = error;
				showError(alert);
			}
		}
		const fetchData = function <T>(this: Grid<T>, pageInfo: PageInfo, storeKey?: string): void {
			if (abortController) {
				abortController.abort();
			}
			abortController = new AbortController()
			ApplicationService.getEntities(pageInfo, this.controller, abortController, this.onFetchDataError, storeKey)
				.then(json => {
					const response = json as ApiResponse<PageResult<T>>;
					if (response.errorMessage) {
						throw response;
					}
					if (storeKey) {
						VersionsStore.putData(this.controller, storeKey, response);
					}
					this.version = VersionsStore.getVersion(this.controller);
					this.entities = response.data?.entities || [];
					this.totalItems = response.data?.totalCount || 0;
					this.loading = false;
					return;
				})
				.catch(reason => BaseService.processError(reason, this.onFetchDataError));
		};
		const loadItems = function <T>(this: Grid<T>, pageInfo: PageInfo): void {
			this.loading = true;
			this.fetchData(pageInfo);
		};
		const reload = function <T>(this: Grid<T>,) {
			this.loadItems({
				page: this.page,
				itemsPerPage: this.itemsPerPage,
				sortBy: this.sortBy,
			});
		};
		const initialize = function () {
		};
		const formatCell = function <T>(header: GridColumn, value: T): string {
			return header.formatter ? header.formatter(value) : (value ? value + '' : '');
		};
		return {
			initialize,
			fetchData,
			loadItems,
			reload,
			formatCell,
			onFetchDataError
		}
	}
	static editableGridMethods<T, TFormProps extends EditFormProps<T>>(): EditableGridFunctions<T, TFormProps> {
		interface EditableGrid<T, TFormProps extends EditFormProps<T>> extends EditableGridFunctions<T, TFormProps>, EditableGridData<T, TFormProps> {
		}

		const methods = GridService.gridMethods();
		const showModal = function (this: EditableGrid<T, TFormProps>, editForm: DefineComponent<TFormProps, any, any>, formProps: ExtractPropTypes<TFormProps>) {
			ModalService
				.show<T, TFormProps>(editForm, formProps)
				.then((entity) => {
					if (entity) {
						this.reload();
					}
				})
		}
		const editItem = function (this: EditableGrid<T, TFormProps>, item: T): void {
			this.editableEntity = {
				...item
			};
			if (this.editForm) {
				type formPropsType = InstanceType<typeof this.editForm>["$props"];
				const formProps: formPropsType = {
					propEntity: this.editableEntity,
					propController: this.controller
				};
				this.showModal(this.editForm, formProps);
			}
		};
		const deleteItem = function (this: EditableGrid<T, TFormProps>, item: T): void {
			this.editableEntity = {
				...item
			};
			if (this.deleteForm) {
				type formPropsType = InstanceType<typeof this.deleteForm>["$props"];
				const formProps: formPropsType = {
					propEntity: this.editableEntity,
					propController: this.controller
				};
				this.showModal(this.deleteForm, formProps);
			}
		};

		return {
			...methods,
			showModal,
			editItem,
			deleteItem
		}
	}
}
