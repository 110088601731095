import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createBlock(_component_v_data_table_server, {
    "items-per-page": _ctx.itemsPerPage,
    "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
    page: _ctx.page,
    "onUpdate:page": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
    "sort-by": _ctx.sortBy,
    "onUpdate:sortBy": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sortBy) = $event)),
    headers: _ctx.headers,
    "items-length": _ctx.totalItems,
    items: _ctx.entities,
    loading: _ctx.loading,
    search: _ctx.search,
    "items-per-page-options": _ctx.itemsPerPageOptions,
    class: "elevation-1",
    "item-value": "name",
    "must-sort": "",
    "onUpdate:options": _ctx.loadItems
  }, _createSlots({
    bottom: _withCtx(() => []),
    _: 2
  }, [
    _renderList(_ctx.headers, (header) => {
      return {
        name: `item.${header.key}`,
        fn: _withCtx(({ item }) => [
          _renderSlot(_ctx.$slots, "cellTemplate", _normalizeProps(_guardReactiveProps({ header, item, formatCell: _ctx.formatCell })), () => [
            (header.key == 'actions')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_v_icon, {
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.editItem(item.raw))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-pencil ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_v_icon, {
                    size: "small",
                    onClick: ($event: any) => (_ctx.deleteItem(item.raw))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-delete ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.formatCell(header, item.raw[header.key])), 1)
                ], 64))
          ])
        ])
      }
    })
  ]), 1032, ["items-per-page", "page", "sort-by", "headers", "items-length", "items", "loading", "search", "items-per-page-options", "onUpdate:options"]))
}