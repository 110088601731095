import { PageInfo } from '@/interfaces/Search'
import { IEntity } from '@/interfaces/Data'
import { HttpError, onErrorFunc } from '@/interfaces/Service';
import { UserService } from '@/services/UserService';
import { VersionsStore } from '@/Stores/VersionStore';

export class ApplicationService {
	static getAuthHeaders() {
		const headers = {} as any;
		const token = UserService.getUserToken();
		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
		}
		return headers;
	}
	static getEntities(pageInfo: PageInfo, controller: string, abortController: AbortController, onError?: onErrorFunc, storeKey?: string) {
		const signal = abortController.signal;
		if (storeKey) {
			const storeData = VersionsStore.getData(controller, storeKey);
			let resolveFunc = (value: unknown) => { };
			if (storeData) {
				const promise = new Promise((resolve) => {
					resolveFunc = resolve;
				});
				if (resolveFunc) {
					setTimeout(() => { resolveFunc(storeData) }, 0);
				}
				return promise;
			}
		}

		return fetch(`/api/${controller}/search`, {
			headers: {
				...this.getAuthHeaders(),
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: 'POST',
			body: JSON.stringify(pageInfo),
			signal
		})
			.then(r => {
				if (r.ok) {
					return r.json();
				}
				else {
					throw { status: r.status, text: r.text() } as HttpError;
				}
			})
	}
	static updateEntity(entity: IEntity, controller: string, onError?: onErrorFunc) {
		const method = entity.id || 0 > 0 ? 'PUT' : 'POST';
		return fetch(`/api/${controller}`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: method,
			body: JSON.stringify(entity),
		})
			.then(r => {
				if (r.ok) {
					return r.json()
				}
				else {
					throw { status: r.status, text: r.text() } as HttpError;
				}
			})
	}
	static getEntity(id: number, controller: string, onError?: onErrorFunc) {
		const method = 'GET';
		return fetch(`/api/${controller}/${id}`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: method
		})
			.then(r => {
				if (r.ok) {
					return r.json()
				}
				else {
					throw { status: r.status, text: r.text() } as HttpError;
				}
			})
	}
	static deleteEntity(entity: IEntity, controller: string, onError?: onErrorFunc) {
		return fetch(`/api/${controller}`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: 'DELETE',
			body: JSON.stringify(entity),
		})
			.then(r => {
				if (r.ok) {
					return r.json();
				}
				else {
					throw { status: r.status, text: r.text() } as HttpError;
				}
			})
	}

}