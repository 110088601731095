import { VForm } from 'vuetify/components'

export class FormService {
	static processIfValid(form: VForm, success: () => void, error: (error: string) => void) {
		form
			.validate()
			.then((value) => {
				if (value.valid) {
					success();
				}
				else {
					let errorMessage = "";
					value.errors.forEach((objError) => {
						objError.errorMessages.forEach((error => {
							errorMessage += errorMessage ? "\n" : "";
							errorMessage += error;
						}));
					})
					error(errorMessage);
				}
			});
	}
}