import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43363e6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    "max-width": "500px"
  }, {
    default: _withCtx(() => [
      (_ctx.alert)
        ? (_openBlock(), _createBlock(_component_v_alert, {
            key: 0,
            class: "alert-modal",
            color: _ctx.alertContext.getAlertTypeName(_ctx.alert?.type),
            icon: _ctx.alertContext.getAlertTypeName(_ctx.alert?.type, '$'),
            closable: true
          }, {
            close: _withCtx(({ }) => [
              _createVNode(_component_v_btn, {
                icon: "mdi-close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.alert = null))
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("pre", {
                innerHTML: _ctx.alert?.message
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["color", "icon"]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps(_ctx.params, {
        onCloseModal: _ctx.close,
        class: "modal-container"
      }), null, 16, ["onCloseModal"]))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}