
	import { defineComponent, DefineComponent, UnwrapRef } from "vue";
	import { ModalService } from "@/services/ModalService";
	import { Alert, AlertType } from '@/interfaces/Notification';
	import { GlobalAlertService } from "@/services/GlobalAlertService";

	export default (function defineGenericComponent<T, TProps>() {
		interface Data {
			alert: null | Alert,
			alertContext: GlobalAlertService,
			visible: boolean,
			component: null | DefineComponent<TProps, any, any>,
			params: null | TProps,
			resolve: Function,
			reject: Function
		}

		return defineComponent({
			data(): Data {
				return {
					alert: null,
					alertContext: GlobalAlertService,
					visible: false,
					component: null,
					params: null,
					resolve: (val: T) => { },
					reject: (val: T) => { },
				}
			},
			mounted() {
				ModalService.setContext(this);
			},
			watch: {
				visible(newValue, oldValue) {
					if (!newValue) {
						GlobalAlertService.removeContext(this);
					}
				}
			},
			methods: {
				show(component: DefineComponent<TProps, any, any>, params: TProps, css: string): Promise<T> {
					GlobalAlertService.setContext(this);
					this.component = component;
					this.params = params as UnwrapRef<TProps>;
					this.visible = true;

					return new Promise<T>((resolve, reject) => {
						this.resolve = resolve;
						this.reject = reject;
					});
				},

				showAlert(alert: Alert) {
					this.alert = alert;
				},

				close(result: T) {
					this.visible = false;
					this.component = null;
					this.params = null;
					this.alert = null;

					this.resolve(result);
				}
			}
		});
	})();
