
	import { defineComponent, PropType } from 'vue';
	import { IEntity } from "@/interfaces/Data";
	import { ApplicationService } from "@/services/ApplicationService";
	import { PageResult } from "@/interfaces/Search";
	import { ApiResponse } from '@/interfaces/ApiResponse';
	import { AlertType } from '@/interfaces/Notification';
	import { GlobalAlertService } from '@/services/GlobalAlertService';
	import { BaseService } from '@/services/BaseService';
	import { VersionsStore } from '@/Stores/VersionStore';
	import { HttpError } from '@/interfaces/Service';

	interface Data {
		selectedItems: IEntity[],
		items: IEntity[],
		abortController: null | AbortController,
		progress: number,
		initialLoad: boolean,
		searching: number,
	}

	export default defineComponent({
		name: 'FormCombobox',
		props: {
			propTitle: {
				type: String,
				default: ''
			},
			propController: {
				type: String,
				default: ''
			},
			propDisplayName: {
				type: String,
				default: ''
			},
			propSelectedItems: {
				type: Object as PropType<IEntity[]> | PropType<IEntity>,
				default: null
			},
			propItems: {
				type: Object as PropType<IEntity[]>,
				default: null
			},
			propUseServerSearch: {
				type: Boolean,
				default: false
			},
			propItemsCount: {
				type: Number,
				default: 100
			},
			propMultiple: {
				type: Boolean,
				default: true,
			},
			propDisabled: {
				type: Boolean,
				default: false,
			},
			propRules: {
				type: Object as PropType<any[]>,
				default: () => [] as Object
			}
		},
		data(): Data {
			return {
				initialLoad: !this.propItems?.length,
				selectedItems: this.propSelectedItems ? (Array.isArray(this.propSelectedItems) ? this.propSelectedItems : [this.propSelectedItems]) : [],
				items: this.propItems || [],
				abortController: null,
				progress: 0,
				searching: 0,
			}
		},
		created() {
			this.initialize();
		},
		watch: {
			propSelectedItems: function (newVal, oldVal) {
				if (oldVal == newVal) return;
				if (Array.isArray(newVal)) {
					this.selectedItems = newVal;
					const items = (this.selectedItems || []).concat(this.items);
					this.items = items.filter((item, index) => items.findIndex(i => i.id == item.id) === index);
				}
				else {
					this.selectedItems = newVal ? newVal : [];
				}
			},
			selectedItems: function (newVal, oldVal) {
				if (oldVal == newVal) return;
				this.$emit('update:propSelectedItems', newVal)
			},
			items: function (newVal, oldVal) {
				this.$emit('update:propItems', newVal);
			}
		},
		methods: {
			initialize() {
				if (this.initialLoad) {
					this.loadItems();
				}
			},
			filterInvalid(items: string | IEntity | IEntity[]) {
				if (Array.isArray(items)) {
					const filtered = items.filter(item => item.id);
					this.selectedItems = filtered;
				}
			},
			removeSelected(list: any[], item: any) {
				list?.splice(list?.indexOf(item), 1)
			},
			populate(entities: IEntity[]) {
				const items = (this.selectedItems || []).concat(entities);
				this.items = items.filter((item, index) => items.findIndex(i => i.id == item.id) === index);
			},
			loadItems(search?: string, isSerach: boolean = false) {
				const storeKey = `combobox-${this.propItemsCount}-${search ?? ''}`;
				this.progress++;
				this.searching += isSerach ? 1 : 0;
				if (this.abortController) {
					this.abortController.abort();
				}
				this.abortController = new AbortController();
				ApplicationService.getEntities({ page: 1, itemsPerPage: this.propItemsCount, search: search }, this.propController, this.abortController, this.onError, storeKey)
					.then((json) => {
						const response = json as ApiResponse<PageResult<IEntity>>;
						if (response.errorMessage) {
							throw response;
						}
						VersionsStore.putData(this.propController, storeKey, response);
						this.populate(response.data ? response.data.entities : []);
					})
					.catch(reason => BaseService.processError(reason, this.onError))
					.finally(() => {
						this.progress--;
						this.searching -= isSerach ? 1 : 0;
					});
			},
			onError(error: string, reason?: HttpError, httpStatus?: number, canBeIgnored?: boolean) {
				if (!canBeIgnored) {
					GlobalAlertService.showAlert({ type: AlertType.error, message: error });
				}
			},
		},
	});
