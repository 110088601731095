import { HttpError, onErrorFunc } from "@/interfaces/Service";
import { BaseService } from "@/services/BaseService";
import { User } from "@/interfaces/User";
import { router } from "@/router";
import { ApiResponse } from "@/interfaces/ApiResponse";

export class UserService {
	static user: null | User;
	static login(username: string, password: string, returnUrl?: string, onError?: onErrorFunc) {
		const method = 'POST';
		return fetch(`/api/account/login`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: method,
			body: JSON.stringify({
				username: username,
				password: password,
			}),
		})
			.then(r => {
				if (r.ok) {
					return r.json()
				}
				else {
					throw { status: r.status, text: r.text() } as HttpError;
				}
			})
			.then(json => {
				const response = json as ApiResponse<User>;
				if (response.errorMessage) {
					throw response;
				}
				this.user = response.data;
				sessionStorage.setItem('user', JSON.stringify(this.user));
				router.push(returnUrl || '/');
			})
			.catch(reason => BaseService.processError(reason, onError));
	}

	static changePassword(userId: number, password: string, onError?: onErrorFunc) {
		const method = 'POST';
		return fetch(`/api/account/${userId}/password`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: method,
			body: JSON.stringify({
				username: '',
				password: password
			})
		})
			.then(r => {
				if (r.ok) {
					return r.json()
				}
				else {
					throw { status: r.status, text: r.text() } as HttpError;
				}
			})
			.catch(reason => BaseService.processError(reason, onError));
	}

	static getUser() {
		if (!this.user) {
			try {
				this.user = JSON.parse(sessionStorage.getItem('user') ?? '');
			}
			catch (e) {
				//
			}
		}
		return this.user;
	}

	static getUserToken() {
		return this.getUser()?.token;
	}

	static getDisplayName() {
		const user = this.getUser();
		if (user) {
			return `${user.lastName}${user.lastName ? ", " : ''}${user.firstName}`;
		}
		return 'Anonymous';
	}

	static dropUserToken() {
		this.user = null;
		sessionStorage.setItem('user', '');
		router.go(0);
	}
}