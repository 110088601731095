import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_combobox = _resolveComponent("v-combobox")!

  return (_openBlock(), _createBlock(_component_v_combobox, {
    modelValue: _ctx.selectedItems,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItems) = $event)),
      _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterInvalid($event)))
    ],
    items: _ctx.items,
    loading: _ctx.progress,
    "item-title": _ctx.propDisplayName,
    "item-value": "id",
    label: _ctx.propTitle,
    "onUpdate:search": _cache[2] || (_cache[2] = ($event: any) => (_ctx.propUseServerSearch && _ctx.loadItems($event, _ctx.propUseServerSearch))),
    chips: _ctx.propMultiple,
    clearable: _ctx.propMultiple,
    multiple: _ctx.propMultiple,
    variant: "outlined",
    "return-object": true,
    disabled: _ctx.propDisabled,
    rules: _ctx.propRules,
    "hide-details": true
  }, _createSlots({
    chip: _withCtx(({ item, select }) => [
      (_ctx.propMultiple)
        ? (_openBlock(), _createBlock(_component_v_chip, {
            key: 0,
            closable: true,
            onClick: select,
            "onClick:close": ($event: any) => (_ctx.removeSelected(_ctx.selectedItems, item.value))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.raw[_ctx.propDisplayName]), 1)
            ]),
            _: 2
          }, 1032, ["onClick", "onClick:close"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(item.raw[_ctx.propDisplayName]), 1)
          ], 64))
    ]),
    _: 2
  }, [
    (_ctx.initialLoad)
      ? {
          name: "append",
          fn: _withCtx(({}) => [
            _createVNode(_component_v_progress_circular, {
              indeterminate: "",
              class: _normalizeClass({ invisible: !_ctx.searching })
            }, null, 8, ["class"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue", "items", "loading", "item-title", "label", "chips", "clearable", "multiple", "disabled", "rules"]))
}