
	import { defineComponent, DefineComponent, PropType, onActivated } from 'vue';
	import { EditableGridData, GridColumn } from '@/interfaces/Grid'
	import { GridService } from '@/services/GridService'
	import { EditFormProps } from '@/interfaces/Form';
	import { VersionsStore } from '@/Stores/VersionStore';

	export default (function defineGenericComponent<T, TFormProps extends EditFormProps<T>>() {
		return defineComponent({
			name: 'EditableDataGrid',
			props: {
				propAddItem: {
					type: Object as PropType<T>,
					default: null,
				},
				propHeaders: {
					type: Array as PropType<Array<GridColumn>>,
					default: () => [],
				},
				propEditForm: {
					type: Object as PropType<DefineComponent<TFormProps, any, any>>,
					default: null,
				},
				propDeleteForm: {
					type: Object as PropType<DefineComponent<TFormProps, any, any>>,
					default: null,
				},
				propController: {
					type: String,
					default: '',
				},
				propDefaultEntity: {
					type: Object as PropType<T>,
					default: () => { }
				}
			},
			data(): EditableGridData<T, TFormProps> {
				return {
					...GridService.defaultEditableGridData(this.propController, this.propEditForm, this.propDeleteForm),
					headers: this.propHeaders,
					editableEntity: this.propDefaultEntity as T,
				};
			},
			created() {
				onActivated(() => {
					if (this.version != VersionsStore.getVersion(this.controller)) {
						this.reload();
					}
				});
				this.initialize();
			},
			watch: {
				propAddItem: function (newVal, oldVal) {
					if (newVal) {
						this.editItem(newVal);
					}
				}
			},
			methods: {
				...GridService.editableGridMethods(),
			}
		});
	})();
