import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_alert, {
            color: "info",
            icon: "$info",
            text: "Confirmation"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, { readonly: _ctx.progress }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Are you sure you want to delete item? ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _withDirectives(_createVNode(_component_v_progress_linear, { indeterminate: "" }, null, 512), [
                    [_vShow, _ctx.progress]
                  ])
                ]),
                _: 1
              }, 8, ["readonly"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            color: "blue-darken-1",
            variant: "text",
            disabled: _ctx.progress,
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createTextVNode(" No ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_v_btn, {
            color: "blue-darken-1",
            variant: "text",
            disabled: _ctx.progress,
            onClick: _ctx.deleteEntity
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Yes ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}