import moment from 'moment';

export class FormatterService {
	static defaultDate() {
		return this.date("DD MMM YYYY");
	}

	static date(format: string) {
		return (val: Date) => moment(String(val)).format(format);
	}
}